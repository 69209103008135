import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const login = (data) => {
  return axiosClient.post("login", data);
};

const update = () =>
  axiosClient.get(`update`, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });

export { login, update };
