import { message, notification, Button } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { cancelRequestLogin } from "./Auth/authAPI";
import { logout } from "./Auth/authAPI";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("auth");
      localStorage.removeItem("token");
      window.location.replace("/");
    }

    return Promise.reject(error);
  }
);

export default instance;
