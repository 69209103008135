import React, { Suspense } from "react";
// import "./App.css";
import "./App.less";
import "animate.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Spin } from "antd";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const Login = React.lazy(() => import("./containers/Login"));
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense
        fallback={
          <div className="loading-component">
            <Spin size="large" tip="Loading..." />
          </div>
        }
      >
        <BrowserRouter>
          <Switch>
            <Redirect exact from="/" to="/jewels" />
            <Route exact path="/login" component={Login} />
            <Route path="/:locale" component={DefaultLayout} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </QueryClientProvider>
  );
}

export default App;
